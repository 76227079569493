interface HeroIcon {
  position: number
  top: number
  rotate: number
  scale: number
  name: string
  side: 'left' | 'right'
}

export const useHeroIcons = () => {
  const heroIcons: HeroIcon[] = [
    // Left side icons
    {
      position: 15,
      top: 15,
      rotate: 15,
      scale: 1.2,
      name: 'i-tabler-plane',
      side: 'left',
    },
    {
      position: 60,
      top: 35,
      rotate: -10,
      scale: 1.4,
      name: 'i-tabler-compass',
      side: 'left',
    },
    {
      position: 25,
      top: 60,
      rotate: 20,
      scale: 1.3,
      name: 'i-tabler-map',
      side: 'left',
    },
    {
      position: 55,
      top: 80,
      rotate: -15,
      scale: 1.2,
      name: 'i-tabler-world',
      side: 'left',
    },

    // Right side icons
    {
      position: 20,
      top: 20,
      rotate: -15,
      scale: 1.3,
      name: 'i-tabler-beach',
      side: 'right',
    },
    {
      position: 55,
      top: 45,
      rotate: 10,
      scale: 1.2,
      name: 'i-tabler-luggage',
      side: 'right',
    },
    {
      position: 25,
      top: 65,
      rotate: -20,
      scale: 1.4,
      name: 'i-tabler-map-pin',
      side: 'right',
    },
    {
      position: 60,
      top: 85,
      rotate: 15,
      scale: 1.2,
      name: 'i-tabler-camera',
      side: 'right',
    },
  ]

  return {
    heroIcons,
  }
}
